import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import {Link} from "gatsby";
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer'


const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="500" color="dark">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const WhyChoose = ({title, copy}) => (
  <>
    <SectionStyled bg="white">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center ">
              <Title color="dark">{title}</Title>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col>
          <Text>

          <RichText render={copy} htmlSerializer={htmlSerializer} />

            </Text>

          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default WhyChoose;
