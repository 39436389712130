import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";
import imgHalf from "../../assets/image/svg/content1.svg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;


const AlignedCol = styled(Col)`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`
const ContentFW = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col md="12">
            <Title variant="hero">
              Social Impact
            </Title>
            <div className="w-100">

            </div>

          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default ContentFW;
