import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import ContentFW from "../sections/about/ContentFW";

import Content1 from "../sections/about/Content1";
import CTA from "../sections/landing1/CTA";
import FAQ from "../sections/about/FAQ";
import WhyChoose from "../sections/about/WhyChoose";
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../utils/htmlSerializer'

const About = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicAbout.nodes[0]

  const ctaDocument = data.allPrismicCta.nodes[0]

  return (
    <>
      <PageWrapper>



        {document.data.first_copy_blocks.map((item, index) => {
          if(index == 0){
            return(
              <Hero title={item.title.text} image={item.image.localFile.url}>
                  <RichText render={item.copy.raw} htmlSerializer={htmlSerializer} />
            </Hero>
            )
  
          }else{
            return(
              <Content title={item.title.text} image={item.image.localFile.url} copy={item.copy.raw}/>

            )
          }
        }
        )}

        {document.data.full_width_copy_blocks.map((item, index) => (
        <WhyChoose title={item.title.text} copy={item.copy.raw} />
        ))}

        <FAQ document={document.data.how_we_help_blocks}/>

        <CTA document={ctaDocument.data} />

      </PageWrapper>
    </>
  );
};
export default About;

export const query = graphql`
query AboutPage {
  allPrismicCta {
    nodes {
      data {
        cta_title {
          text
        }
        cta_button_text {
          text
        }
      }
    }
  }
  allPrismicAbout {
    nodes {
      data {
        first_copy_blocks {
          copy {
            raw
          }
          cta_text {
            text
          }
          image {
            localFile {
              id
              url
            }
          }
          title {
            text
          }
        }
        full_width_copy_blocks {
          title {
            text
          }
          copy {
            raw
          }
        }
        how_we_help
        how_we_help_blocks {
          title {
            text
          }
        }
        how_we_help_cta {
          text
        }
        how_we_help_title {
          text
        }
        page_description {
          text
        }
        page_title {
          text
        }
      }
    }
  }
}
`
