import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../utils/htmlSerializer'


const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;


const AlignedCol = styled(Col)`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`


const Content = ({title, image, copy}) => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="7">
            <Title>
              {title}
            </Title>
            <div className="h-100">
              <img src={image} alt="" className="img-half img-fluid pb-4 pb-md-0" />
            </div>

          </Col>
          <AlignedCol lg="5" className="pl-lg-5">
            <Text>
            <RichText render={copy} htmlSerializer={htmlSerializer} />

            </Text>
          </AlignedCol>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
