import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import aboutImage from "../../assets/image/svg/hero_about.svg";

const SectionStyled = styled(Section)``;

const AlignedCol = styled(Col)`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`
const Hero = ({ title, children, image, ...rest }) => {
  return (
    <>
      <SectionStyled
        pt={["120px!important", null, "190px!important"]}
        pb={["50px!important", null, "10px!important"]}
        {...rest}
      >
        <Container>
          <Row>
            <Col lg="6">
              <div>
                <Title>{title}</Title>
                <Text>{children}</Text>
              </div>
            </Col>
            <AlignedCol lg="6" className='pt-5 pt-md-0'>
              <img src={image} className='w-100'></img>
            </AlignedCol>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
